import React, { useEffect, useState, useContext } from "react";
import "./productPage.css";
import Header from "../header/header";
import { Divider, Breadcrumb, message, Spin } from "antd";
import ProductCard from "../productCard/ProductCard";
import { Carousel } from "react-responsive-carousel";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import { AppContext } from "../../AppContext";

function ProductPage() {
  const [isLoading, setIsLoading] = useState(true);

  const { cartItemsChanged, setCartItemsChanged, cartItems } =
    useContext(AppContext);

  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const { productId } = useParams();
  const [product, setProduct] = useState({});
  const [activeColor, setActiveColor] = useState("");
  const [availableSizes, setAvailableSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [stockInfo, setStockInfo] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [randomProducts, setRandomProducts] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      const res = await axios.get("https://api.detacheg.com/api/products/random");
      setRandomProducts(res.data);
    };
    getProducts();
  }, []);

  const handleSizeChange = (size) => {
    setSelectedSize(size);
    updateStockInfo(size);
  };

  const selectFirstAvailableSize = (subItems) => {
    const availableSubItem = subItems?.find((item) => item.quantity > 0);
    return availableSubItem ? availableSubItem?.size : "";
  };

  const updateStockInfo = (size) => {
    const selectedItem = product?.items?.find(
      (item) => item.color === activeColor
    );
    const selectedSubItem = selectedItem?.subItems?.find(
      (subItem) => subItem.size === size
    );

    if (selectedSubItem?.quantity === 0) {
      setStockInfo("Out Of Stock");
    } else if (selectedSubItem?.quantity <= 3) {
      setStockInfo(`Only ${selectedSubItem?.quantity} products left!`);
    } else {
      setStockInfo("");
    }
  };

  const checkAllSizesOutOfStock = (subItems) => {
    return subItems.every((subItem) => subItem?.quantity === 0);
  };

  useEffect(() => {
    const getProduct = async () => {
      setIsLoading(true);
      const res = await axios.get(
        `https://api.detacheg.com/api/products/${productId}`
      );
      setProduct(res.data);

      if (res?.data?.items?.length > 0) {
        const initialColor = res?.data?.items[0]?.color;
        setActiveColor(initialColor);
        const initialItem = res?.data?.items?.find(
          (item) => item?.color === initialColor
        );
        setImages(initialItem?.images);
        setSelectedImage(initialItem?.images[0]);
        setAvailableSizes(initialItem?.subItems);
        const firstAvailableSize = selectFirstAvailableSize(
          initialItem?.subItems
        );
        setSelectedSize(firstAvailableSize);
        if (checkAllSizesOutOfStock(initialItem?.subItems)) {
          setStockInfo("Out Of Stock");
        } else {
          updateStockInfo(firstAvailableSize);
        }
      }
      setIsLoading(false);
    };
    getProduct();
  }, [productId]);

  const handleColorChange = (color) => {
    setIsLoading(true);
    setActiveColor(color);
    const selectedItem = product?.items?.find((item) => item.color === color);
    setImages(selectedItem?.images);
    setSelectedImage(selectedItem?.images[0]);
    setAvailableSizes(selectedItem?.subItems);
    const firstAvailableSize = selectFirstAvailableSize(selectedItem?.subItems);
    setSelectedSize(firstAvailableSize);
    if (checkAllSizesOutOfStock(selectedItem?.subItems)) {
      setStockInfo("Out Of Stock");
    } else {
      updateStockInfo(firstAvailableSize);
    }
    setIsLoading(false);
  };

  const handleImageChange = (image) => {
    setSelectedImage(image);
  };

  const isLightColor = (color) => {
    const hex = color?.replace("#", "");
    const r = parseInt(hex?.substring(0, 2), 16);
    const g = parseInt(hex?.substring(2, 4), 16);
    const b = parseInt(hex?.substring(4, 6), 16);
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luminance > 128;
  };

  const colorOptions = product?.items?.map((item, index) => {
    const tickColor = isLightColor(item.color) ? "black" : "white";
    const tickSvg = `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="${tickColor}" width="24" height="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2l-3.5-3.5a1 1 0 0 1 1.41-1.42L9 13.36l6.88-6.89a1 1 0 0 1 1.41 1.42L9 16.2z"/></svg>`;

    return (
      <div key={index}>
        <input
          data-image={item.color}
          type="radio"
          id={item.color}
          name="color"
          value={item.color}
          checked={activeColor === item.color}
          onChange={() => handleColorChange(item.color)}
        />
        <label htmlFor={item.color}>
          <span
            style={{
              backgroundColor: item.color,
              backgroundImage:
                activeColor === item.color ? `url('${tickSvg}')` : "none",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          ></span>
        </label>
      </div>
    );
  });

  const sizeButtons = availableSizes.map((subItem, index) => (
    <button
      key={index}
      disabled={subItem.quantity === 0}
      className={`${subItem.quantity === 0 ? "disabled" : ""} ${
        selectedSize === subItem.size ? "selected" : ""
      }`}
      onClick={() => handleSizeChange(subItem.size)}
    >
      {subItem.size.toUpperCase()}
    </button>
  ));

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const allSizesOutOfStock = checkAllSizesOutOfStock(availableSizes);

  const addToCart = async () => {
    const selectedItem = product.items.find(
      (item) => item.color === activeColor
    );
    const selectedSubItem = selectedItem.subItems.find(
      (subItem) => subItem.size === selectedSize
    );

    const newProduct = {
      product: {
        productId: product._id,
        name: product.name,
        longDescription: product.longDescription,
      },
      item: {
        itemID: selectedSubItem._id,
        size: selectedSize,
        color: activeColor,
        quantity: 1,
        images: selectedItem.images,
        weight: selectedSubItem.weight,
        priceAfterDiscount: selectedSubItem.priceAfterDiscount,
        status: "pending",
      },
    };

    try {
      const token = localStorage.getItem("token");
      const res = await axios.post(
        `https://api.detacheg.com/api/cart/add-product/${token}`,
        newProduct
      );
      if (res.data.token) {
        localStorage.setItem("token", res.data.token);
      }
      if (res.data === "Requested quantity exceeds available stock") {
        message.error("Requested quantity exceeds available stock");
        return;
      }
      setCartItemsChanged(!cartItemsChanged);
      message.success("Item added to cart successfully!");
    } catch (error) {
      message.error("Error adding to cart");
    }
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Divider />
          <Breadcrumb
            style={{ marginLeft: "20px" }}
            items={[
              { title: <a href="/">Home</a> },
              {
                title: (
                  <a href={`/collection/${product?.collection}`}>
                    {product?.collection}
                  </a>
                ),
              },
              { title: `${product?.name}` },
            ]}
          />
          <main className="productContainer">
            <div className="left-column">
              <div className="galleryContainer">
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={`https://api.detacheg.com/api/images/getImage?name=${image}`}
                    alt={`Product ${index}`}
                    className={`galleryImages ${
                      selectedImage === image ? "active" : ""
                    }`}
                    onClick={() => handleImageChange(image)}
                  />
                ))}
              </div>
              <div>
                <img
                  className="selectedImage"
                  src={`https://api.detacheg.com/api/images/getImage?name=${selectedImage}`}
                  alt="Selected"
                />
              </div>
            </div>

            <div className="right-column">
              <div className="product-description">
                <span>{product?.collection}</span>
                <h1>{product?.name}</h1>
                <div
                  className={`long-description ${
                    isExpanded ? "expanded" : "collapsed"
                  }`}
                >
                  {product?.longDescription?.map((section, index) => (
                    <div key={index}>
                      <h3>{section.title}</h3>
                      <ul>
                        {section.shortDescription.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                <button onClick={toggleExpand} className="expand-button">
                  {isExpanded ? "Read Less" : "Read More"}
                </button>
              </div>

              <div className="product-configuration">
                <div className="product-color">
                  <span>Color</span>
                  <div className="color-choose">{colorOptions}</div>
                </div>
                <div className="cable-config">
                  <span>Size</span>
                  <div className="cable-choose">{sizeButtons}</div>
                  <div className="stock-info">{stockInfo}</div>
                </div>
                <div className="product-price">
                  {availableSizes?.map((subItem, index) => {
                    if (subItem.quantity > 0 && subItem.size === selectedSize) {
                      const selectedItem = product.items.find(
                        (item) => item.color === activeColor
                      );
                      const selectedSubItem = selectedItem.subItems.find(
                        (item) => item.size === subItem.size
                      );
                      if (selectedSubItem) {
                        return (
                          <React.Fragment key={index}>
                            {selectedSubItem.discountType === "percentage" ? (
                              <span
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "10px",
                                }}
                              >
                                <div
                                  style={{ fontSize: "15px", color: "green" }}
                                >
                                  {selectedSubItem.discount}% OFF
                                </div>
                                <div>
                                  <del style={{ color: "gray" }}>
                                    EGP {selectedSubItem.price}
                                  </del>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    EGP {selectedSubItem.priceAfterDiscount}{" "}
                                  </span>
                                </div>
                              </span>
                            ) : selectedSubItem.discountType === "fixed" ? (
                              <span
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "10px",
                                }}
                              >
                                <div
                                  style={{ fontSize: "15px", color: "green" }}
                                >
                                  {selectedSubItem.discount} EGP OFF
                                </div>
                                <div>
                                  <del style={{ color: "gray" }}>
                                    EGP {selectedSubItem.price}
                                  </del>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    EGP {selectedSubItem.priceAfterDiscount}{" "}
                                  </span>
                                </div>
                              </span>
                            ) : (
                              <span style={{ fontWeight: "bold" }}>
                                EGP {selectedSubItem.price}
                              </span>
                            )}
                          </React.Fragment>
                        );
                      }
                    }
                    return null;
                  })}
                  <button
                    className={
                      allSizesOutOfStock ? "cart-btn-disabled" : "cart-btn"
                    }
                    disabled={allSizesOutOfStock}
                    onClick={addToCart}
                  >
                    Add to cart
                  </button>
                </div>
              </div>
            </div>
          </main>
          <h1 style={{ textAlign: "center", marginTop: "50px" }}>
            You may also like
          </h1>
          <div className="carousel" style={{ marginTop: "50px" }}>
            <div
              className="carousel-container"
              style={{ display: "flex", gap: "10px", marginBottom: "20px" }}
            >
              {randomProducts.map((product) => {
                const minPrice = Math.min(
                  ...product?.items?.map((item) =>
                    Math.min(...item.subItems.map((subItem) => subItem.price))
                  )
                );
                const minPriceAfterDiscount = product?.items?.map(
                  (item) =>
                    item.subItems.find((subItem) => subItem.price === minPrice)
                      ?.priceAfterDiscount
                )[0];
                return (
                  <ProductCard
                    key={product._id}
                    id={product._id}
                    image={`https://api.detacheg.com/api/images/getImage?name=${product?.items[0]?.images[0]}`}
                    brand={{ name: product.name }}
                    price={minPrice}
                    priceAfterDiscount={minPriceAfterDiscount}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  );
}

export default ProductPage;
