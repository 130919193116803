import { Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

export function Subscribers() {
  const [isLoading, setIsLoading] = useState(true);

  const [subscribers, setSubscribers] = useState([]);

  useEffect(() => {
    const getSubscribers = async () => {
      setIsLoading(true);
      const response = await axios.get(`https://api.detacheg.com/api/subscribe`);
      setSubscribers(response.data);
      setIsLoading(false);
    };
    getSubscribers();
  }, []);

  return (
    <div style={{ width: "1200px" }}>
      <Table
        columns={[
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Date",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt) => {
              return new Date(createdAt).toLocaleDateString();
            },
          },
        ]}
        dataSource={subscribers}
        pagination={{ pageSize: 10 }}
        loading={isLoading}
      />
    </div>
  );
}

export default Subscribers;
