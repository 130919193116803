import React, { useEffect, useState, useContext } from "react";
import style from "./cart.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import CartItem from "../cartItems/cartItems";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AppContext } from "../../AppContext";
import { message } from "antd";

export default function Cart({ isOpen, onClose }) {
  const { cartItems, cartTotal } = useContext(AppContext);

  const navigate = useNavigate();

  const renderCartItems = () => {
    return cartItems?.map((cartItem) => {
      return cartItem?.items.map((item) => (
        <CartItem
          productID={cartItem.product.productId}
          subItemID={item.itemID}
          image={`https://api.detacheg.com/api/images/getImage?name=${item.images[0]}`} // Assuming you want to display the first image
          name={cartItem.product.name}
          size={item.size}
          color={item.color}
          price={item.priceAfterDiscount}
          quantity={item.quantity}
        />
      ));
    });
  };

  return (
    <div>
      <div
        className={`${style.overlay} ${isOpen ? style.overlayVisible : ""}`}
        onClick={onClose}
      ></div>
      <div className={`${style.cart} ${isOpen ? style.open : ""}`}>
        <div className={style.cartContent}>
          <div className={style.cartHeader}>
            <h2>Cart</h2>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </div>
          {/* Cart items container with scroll */}
          <div className={style.cartItemsContainer}>{renderCartItems()}</div>
          {/* Order notes section */}
          {/* <div className={style.orderNoteContainer}>
                        <label htmlFor="orderNote" className={style.orderNoteLabel}>Notes</label>
                        <textarea
                            id="orderNote"
                            className={style.orderNote}
                            placeholder="Add your note..."
                            value={orderNote}
                            onChange={handleOrderNoteChange}
                        />
                    </div> */}
          <div className={style.subtotalContainer}>
            <div className={style.subtotalText}>SUBTOTAL</div>
            <div className={style.subtotalInt}>
              EGP {cartTotal.toFixed(2)}
            </div>{" "}
            {/* Subtotal dynamically calculated */}
          </div>
          <div className={style.shipping}>
            Shipping and vouchers are calculated at checkout.
          </div>
          <div className={style.buttonContainer}>
            <button
              className={`${style.checkoutButton}`}
              role="button"
              onClick={() => {
                if (cartItems === undefined || cartItems === null) {
                  message.error("Please add items to the cart first");
                  return;
                }
                if (cartItems?.length === 0) {
                  message.error("Please add items to the cart first");
                  return;
                }
                navigate("/checkout");
                onClose();
              }}
            >
              Checkout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
