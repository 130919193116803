import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import style from "./drawer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faHeart,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import Cart from "../cart/cart"; // Import the Cart component

export default function Drawer() {
  const [collections, setCollections] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        // Replace with your API endpoint to fetch collections
        const response = await fetch(
          "https://api.detacheg.com/api/collections/published"
        );
        const data = await response.json();
        setCollections(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCollections();
  }, []);

  const [cartOpened, setCartOpened] = useState(false); // State for managing cart visibility

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.body.style.backgroundColor = "white";
    document.body.style.overflow = "auto";
  }

  function toggleCart() {
    setCartOpened((prevState) => !prevState); // Toggle the cart visibility
  }

  return (
    <>
      <div id="mySidenav" className={style.sidenav}>
        <a className={style.closebtn} onClick={closeNav}>
          <FontAwesomeIcon icon={faXmark} />
        </a>
        <a href="/" className={style.sideBarItems}>
          Home
        </a>
        {collections.map((collection) => (
          <a
            key={collection._id} // Use a unique identifier for each collection
            href={`/collection/${collection.name}`} // Dynamically construct the collection URL
            className={style.sideBarItems}
            onClick={() => closeNav()} // Close the drawer after navigation
          >
            {collection.name}
          </a>
        ))}
        {/* <a href="#" className={style.sideBarItems}>Contact Us</a> */}
        {/* <div className={style.bottomIcons}>
          <FontAwesomeIcon icon={faHeart} className={style.icon} />
          <FontAwesomeIcon
            className={`${style.headerIcons} ${style.whiteIcon}`}
            icon={faShoppingCart}
            onClick={toggleCart}
          />
        </div> */}
        <Cart isOpen={cartOpened} onClose={toggleCart} />
      </div>
    </>
  );
}
