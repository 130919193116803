import React, { useEffect, useState, useContext } from "react";
import style from "./header.module.css";
import logo from "../../assets/images/BlackLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faShoppingCart,
  faHeart,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Drawer from "../drawer/drawer";
import Cart from "../cart/cart"; // Import the Cart component
import { useNavigate } from "react-router-dom";
import { ReactTicker } from "@guna81/react-ticker";
import axios from "axios";
import { Badge } from "antd";
import { AppContext } from "../../AppContext";

export default function Header({ isLoading }) {
  const { cartTotalQuantity } = useContext(AppContext);

  const [searchBarOpened, setSearchBarOpened] = useState(false);
  const [cartOpened, setCartOpened] = useState(false); // State for managing cart visibility

  function openNav() {
    document.getElementById("mySidenav").style.width = "350px";
    // document.getElementById("bg").style.opacity = "10%";
    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    document.body.style.overflow = "hidden";
  }

  function openSearchBar() {
    setSearchBarOpened(true);
    // document.getElementById("bg").style.opacity = "10%";
    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    document.body.style.overflow = "hidden";
  }

  function closeSearchBar() {
    setSearchBarOpened(false);
    // document.getElementById("bg").style.opacity = "100%";
    document.body.style.backgroundColor = "white";
    document.body.style.overflow = "auto";
  }

  function toggleCart() {
    setCartOpened((prevState) => !prevState); // Toggle the cart visibility
  }

  const navigate = useNavigate();

  const data = [
    {
      id: 1,
      value: "adham",
    },
    {
      id: 2,
      value: "ahmed",
    },
  ];

  const [news, setNews] = useState(data);

  useEffect(() => {
    const getNews = async () => {
      const res = await axios.get("https://api.detacheg.com/api/news/active");
      const news = res.data.map((item) => {
        return {
          id: item._id,
          value: item.name,
        };
      });
      setNews(news);
    };
    getNews();
  }, []);

  const renderItem = (item) => {
    return (
      <p
        style={{
          whiteSpace: "nowrap",
          color: "white",
          marginRight: "30px",
        }}
      >
        {data.length > 0 ? item.value : item.value}
      </p>
    );
  };

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div style={{ width: "100%" }}>
          <div className={style.newsBar}>
            <ReactTicker
              data={news}
              component={renderItem}
              speed={1000}
              keyName="_id"
              tickerStyle={{
                width: "100%",
                height: "20px",
              }}
              tickerClassName="news-ticker"
            />
          </div>
          <div className={style.headerWrapper}>
            <div className={style.toolbar}>
              <div className="page-width">
                <div className={style.toolbarContent}>
                  <div className={style.toolbarItem}>
                    <ul className={style.inlineList}>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.instagram.com/detach.eg/"
                        >
                          <svg
                            className={style.icon}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            id="instagram"
                          >
                            <path d="M11 0H5a5 5 0 0 0-5 5v6a5 5 0 0 0 5 5h6a5 5 0 0 0 5-5V5a5 5 0 0 0-5-5zm3.5 11c0 1.93-1.57 3.5-3.5 3.5H5c-1.93 0-3.5-1.57-3.5-3.5V5c0-1.93 1.57-3.5 3.5-3.5h6c1.93 0 3.5 1.57 3.5 3.5v6z"></path>
                            <path d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6.5A2.503 2.503 0 0 1 5.5 8c0-1.379 1.122-2.5 2.5-2.5s2.5 1.121 2.5 2.5c0 1.378-1.122 2.5-2.5 2.5z"></path>
                            <circle cx="12.3" cy="3.7" r=".533"></circle>
                          </svg>
                          <span className={style.iconFallbackText}>Instagram</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.stickyHeader}>
              <header className={style.siteHeader}>
                {searchBarOpened ? (
                  <div className={style.searchBar}>
                    <FontAwesomeIcon
                      className={style.headerIcons}
                      icon={faSearch}
                    />
                    <input type="text" placeholder="Search for products" />
                    <FontAwesomeIcon
                      style={{ width: "20px", cursor: "pointer" }}
                      icon={faXmark}
                      onClick={closeSearchBar}
                    />
                  </div>
                ) : (
                  <div className="page-width">
                    <div className={style.headerLayout}>
                      <div className={style.headerItem}>
                        <FontAwesomeIcon
                          className={style.headerIcons}
                          icon={faBars}
                          onClick={openNav}
                        />
                      </div>
                      <div className={style.headerItem}>
                        <img
                          className={style.logo}
                          src={logo}
                          alt="detach"
                          onClick={() => navigate("/")}
                        />
                      </div>
                      <div className={`${style.rightHeader} ${style.inlineList}`}>
                        <ul className={`${style.rightHeader} ${style.inlineList}`}>
                          <li>
                            <FontAwesomeIcon
                              className={style.headerIcons}
                              icon={faSearch}
                              onClick={openSearchBar}
                            />
                          </li>
                          <li>
                            <Badge count={cartTotalQuantity}>
                              <FontAwesomeIcon
                                className={style.headerIcons}
                                icon={faShoppingCart}
                                onClick={toggleCart}
                              />
                            </Badge>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </header>
            </div>
          </div>
          <Cart isOpen={cartOpened} onClose={toggleCart} />{" "}
          {/* Render the Cart component */}
          <Drawer />
        </div>
      )}
    </>
  );
}
