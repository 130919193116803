import React, { useState, useEffect } from "react";
import "./products.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ProductCard from "../productCard/ProductCard";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Spin } from "antd";

export default function Products({ setIsLoading, isLoading }) {
  const [collections, setCollections] = useState([]);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getCollections = async () => {
      try {
        const res = await axios.get(
          "https://api.detacheg.com/api/collections/published"
        );

        setCollections(res.data);
        const productsRes = await axios.get("https://api.detacheg.com/api/products");

        const filteredProducts = productsRes.data.filter(
          (product) => product.collection !== undefined
        );

        const groupedProducts = {};
        filteredProducts.forEach((product) => {
          if (!groupedProducts[product.collection]) {
            groupedProducts[product.collection] = [];
          }
          groupedProducts[product.collection].push(product);
        });

        const limitedProducts = [];
        for (const collection in groupedProducts) {
          limitedProducts.push(...groupedProducts[collection].slice(0, 4));
        }
        setProducts(limitedProducts);
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setIsLoading(false);
      }
    };

    getCollections();
  }, []); // Empty dependency array since we only want to fetch once on mount

  return (
    <>
      {collections.map((collection) => (
        <div
          key={collection._id}
          style={{ marginBottom: "50px", marginTop: "50px" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: "20px",
            }}
          >
            <h1
              style={{
                textAlign: "center",
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              {collection.name}
            </h1>
            <button
              className="checkoutButton"
              role="button"
              onClick={() => navigate(`/collection/${collection.name}`)}
            >
              View All
            </button>
          </div>
          {products.filter(
            (product) => product.collection === collection.name
          ).length > 0 && (
              <div
                style={{
                  marginBottom: "50px",
                  marginTop: "50px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                <Carousel
                  showThumbs={false}
                  swipeable={true}
                  dynamicHeight={false}
                  showStatus={false}
                  showIndicators={false}
                >
                  <div
                    className="carousel-container"
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginBottom: "20px",
                    }}
                  >
                    {products
                      .filter((product) => product.collection === collection.name)
                      .map((product) => {
                        // Find the minimum price from subItems
                        const minPrice = Math.min(
                          ...product?.items?.map((item) =>
                            Math.min(
                              ...item.subItems.map((subItem) => subItem.price)
                            )
                          )
                        );
                        //get the price after discount of the minPrice subItem
                        const minPriceAfterDiscount = product?.items?.map(
                          (item) =>
                            item.subItems.find(
                              (subItem) => subItem.price === minPrice
                            )?.priceAfterDiscount
                        )[0];

                        return (
                          <ProductCard
                            key={product._id}
                            id={product._id}
                            image={`https://api.detacheg.com/api/images/getImage?name=${product?.items[0]?.images[0]}`}
                            brand={{ name: product.name }}
                            price={minPrice}
                            priceAfterDiscount={minPriceAfterDiscount}
                          />
                        );
                      })}
                  </div>
                </Carousel>
              </div>
            )}
        </div>
      ))}
    </>
  );
}
