import React, { useEffect, useState } from "react";
import bg from "../../assets/images/backgroundUniSex.jpg";
import style from "./background.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

export default function Background({ children }) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [homeData, setHomeData] = useState({});

  useEffect(() => {
    const getHomeData = async () => {
      const response = await axios.get(`https://api.detacheg.com/api/home`);
      setHomeData(response.data[0]);
      setIsLoading(false);
    };
    getHomeData();
  }, []);

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <div className={style.background}>
          <div className={style.imageContainer}>
            <img
              id="bg"
              src={`https://api.detacheg.com/api/images/getImage?name=${homeData?.backgroundImage}`}
              alt=""
            />
            <div className={style.text}>
              <span>NEW COLLECTION</span>
              <span style={{ fontSize: "50px" }}>{homeData?.text}</span>
              <button
                className={style.buttonShop}
                onClick={() =>
                  navigate(`/collection/${homeData?.buttonNavigation}`)
                }
              >
                SHOP NOW
              </button>
            </div>
          </div>
          {children}
        </div>
      )}
    </>
  );
}
