import { Button, Table, message, Tag, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";

const NewsTable = ({ news, setIsUpdated, isUpdated, isLoading }) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "Active",
      align: "center",
      render: (_, { active }) => {
        if (active) {
          return <Tag color="green">Active</Tag>;
        } else {
          return <Tag color="red">Inactive</Tag>;
        }
      },
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      align: "center",
      render: (_, record) => {
        return (
          <div>
            <Button
              onClick={() => activateNews(record._id)}
              type="primary"
              style={{ marginRight: "10px" }}
            >
              Activate
            </Button>
            <Button
              onClick={() => deactivateNews(record._id)}
              type="primary"
              style={{ marginRight: "10px" }}
            >
              Deactivate
            </Button>
            <Popconfirm
              title="Sure to delete?"
              open={open}
              onConfirm={() => handleOk(record._id)}
              onCancel={handleCancel}
            >
              <Button type="primary" onClick={showPopconfirm}>
                Delete
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const activateNews = async (id) => {
    await axios.put(`https://api.detacheg.com/api/news`, {
      _id: id,
      active: true,
    });
    setIsUpdated(!isUpdated);
  };

  const deactivateNews = async (id) => {
    await axios.put(`https://api.detacheg.com/api/news`, {
      _id: id,
      active: false,
    });
    setIsUpdated(!isUpdated);
  };

  const deleteNews = async (id) => {
    await axios.delete(`https://api.detacheg.com/api/news/${id}`);
    setIsUpdated(!isUpdated);
  };

  const [open, setOpen] = useState(false);
  const showPopconfirm = () => {
    setOpen(true);
  };
  const handleOk = (id) => {
    deleteNews(id);
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Table
      rowKey="ID"
      columns={columns}
      dataSource={news}
      pagination={false}
      loading={isLoading}
    />
  );
};
export default NewsTable;
