import Card from "react-bootstrap/Card";
import { Form, Input, Button, message } from "antd";
import NewsTable from "./NewsTable";
import axios from "axios";
import React, { useEffect, useState } from "react";

export function NewsCard() {
  const [isLoading, setIsLoading] = useState(true);

  const [news, setNews] = useState([]);
  const [addedNews, setAddedNews] = useState("");

  const [isUpdated, setIsUpdated] = useState(false);

  const addNews = async () => {
    if (addedNews === "") {
      message.error("Please enter news");
      return;
    }
    await axios.post("https://api.detacheg.com/api/news", {
      name: addedNews,
    });
    setAddedNews("");
    setIsUpdated(!isUpdated);
  };

  const onChange = (e) => {
    setAddedNews(e.target.value);
  };

  useEffect(() => {
    const getNews = async () => {
      setIsLoading(true);
      const res = await axios.get("https://api.detacheg.com/api/news");
      setNews(res.data);
      setIsLoading(false);
    };
    getNews();
  }, [isUpdated]);

  return (
    <Card style={{ width: "1200px" }}>
      {/* <Card.Header>News</Card.Header> */}
      <Card.Body>
        <Form>
          <Form.Item label="News">
            <div style={{ display: "flex", gap: "200px" }}>
              <Input
                placeholder=" Please Enter News"
                onChange={onChange}
                value={addedNews}
              />
              <Button
                onClick={addNews}
                type="primary"
                style={{ float: "right" }}
                htmlType="submit"
              >
                Add
              </Button>
            </div>
          </Form.Item>
          <NewsTable
            news={news}
            setIsUpdated={setIsUpdated}
            isUpdated={isUpdated}
            isLoading={isLoading}
          />
        </Form>
      </Card.Body>
    </Card>
  );
}
