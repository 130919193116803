import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  Modal,
  Form,
  Input,
  Image,
  Upload,
  message,
  DatePicker,
  Switch,
} from "antd"; // Import necessary components
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons"; // Import icon components
import "./collection.css"; // Import custom styles
import { style, width } from "@mui/system";
import axios from "axios";

export function Collections() {
  // Use collectionPage for clarity

  const [isLoading, setIsLoading] = useState(true);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editCollectionId, setEditCollectionId] = useState(null);
  const [form] = Form.useForm();

  const [collectionData, setCollectionData] = useState({
    name: "",
    published: false,
  });

  const handleOk = () => {
    if (editCollectionId) {
      updateCollection();
    } else {
      addCollection();
    }
    form.resetFields();
    setCollectionData({ name: "", published: false });
    setIsModalVisible(false);
  };

  const showModal = () => {
    form.resetFields();
    setCollectionData({ name: "", published: false });
    setIsModalVisible(true);
    setEditCollectionId(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditCollectionId(null);
  };

  const removecollection = async (collectionId) => {
    await axios.delete(`https://api.detacheg.com/api/collections/${collectionId}`);
    setIsUpdated(!isUpdated);
  };

  const editcollection = (collectionId) => {
    const collectionToEdit = collections.find(
      (collection) => collection._id === collectionId
    );
    if (collectionToEdit) {
      form.setFieldsValue({
        name: collectionToEdit.name,
      });
      setCollectionData({
        name: collectionToEdit.name,
        published: collectionToEdit.published,
      });
      setIsModalVisible(true);
      setEditCollectionId(collectionId);
    }
  };

  const [isUpdated, setIsUpdated] = useState(false);

  const updatePublished = async (id, isPublished) => {
    await axios.put(`https://api.detacheg.com/api/collections/${id}`, {
      published: isPublished,
    });
    setIsUpdated(!isUpdated);
  };

  const columns = [
    {
      title: "Collection Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "PUBLISHED",
      key: "published",
      render: (record) =>
        record.published ? (
          <Button
            type="primary"
            size="small"
            onClick={() => updatePublished(record._id, false)}
          >
            UnPublish
          </Button>
        ) : (
          <Button
            type="default"
            size="small"
            onClick={() => updatePublished(record._id, true)}
          >
            Publish
          </Button>
        ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <>
          <Popconfirm
            title="Are you sure you want to remove this collection?"
            placement="right"
            onConfirm={() => removecollection(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="small"
              style={{ backgroundColor: "red", color: "white" }}
            />
          </Popconfirm>
          <Button
            type="primary"
            icon={<EditOutlined />}
            size="small"
            onClick={() => editcollection(record._id)}
          />
        </>
      ),
    },
  ];

  const addCollection = async () => {
    await axios.post("https://api.detacheg.com/api/collections", collectionData);
    setIsUpdated(!isUpdated);
    form.resetFields();
    setCollectionData({ name: "", published: false });
  };

  const updateCollection = async () => {
    const res = await axios.put(`https://api.detacheg.com/api/collections`, {
      _id: editCollectionId,
      name: collectionData.name,
      published: collectionData.published,
    });
    setIsUpdated(!isUpdated);
    form.resetFields();
    setCollectionData({ name: "", published: false });
    setEditCollectionId(null);
  };

  const [collections, setCollections] = useState([]);

  useEffect(() => {
    const getCollections = async () => {
      setIsLoading(true);
      const res = await axios.get("https://api.detacheg.com/api/collections");
      setCollections(res.data);
      setIsLoading(false);
    };
    getCollections();
  }, [isUpdated]);

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        style={{ marginBottom: "20px" }}
        onClick={showModal}
      >
        Add collection
      </Button>
      <Modal
        title="Add collection"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className={style["collection-modal"]}
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleOk}
          form={form}
        >
          <Form.Item
            label={<span style={{ color: "white" }}>Collection Name</span>}
            name="name"
            rules={[
              { required: true, message: "Please enter collection name" },
            ]}
          >
            <Input
              onChange={(e) =>
                setCollectionData({ ...collectionData, name: e.target.value })
              }
              defaultValue={collectionData.name}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Table
        dataSource={collections}
        columns={columns}
        pagination={{ pageSize: 5 }}
        className="collection-table"
        loading={isLoading}
      />
    </>
  );
}
